import React, { createContext, useState } from "react";
import { useHistory } from "react-router-dom";
import get from "lodash/get";

const AuthContext = createContext(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const access_token = localStorage.getItem("access_token");
  const user = localStorage.getItem("user");
  const refresh_token = localStorage.getItem("refresh_token");

  const [authState, setAuthState] = useState({
    access_token,
    refresh_token,
    user: user ? JSON.parse(user) : {},
  });

  const setAuthInfo = ({ access_token, user, refresh_token }) => {
    localStorage.setItem("access_token", access_token);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("refresh_token", refresh_token);

    setAuthState({
      access_token,
      user,
      refresh_token,
    });
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh_token");
    setAuthState({});
    history.push("/login");
  };

  const isAuthenticated = () => {
    if (!authState.access_token || !authState.refresh_token) {
      return false;
    }
    return true;
  };

  const isAdmin = () => {
    return get(authState, "user.role.name") === "ADMIN";
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
